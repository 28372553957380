<template>
    <div class="hello">
        <h1>{{ msg }}</h1>
        <p v-if="qrRes.code === 500">
            {{qrRes.msg}}
        </p>
        <div class="phone-box" v-if="qrRes.code === 0">
            <form @submit.prevent="handleSubmit">
                <input type="tel" id="phone" v-model="phone" placeholder="请输入手机号进行取签" />
                <span v-if="errorMessage" class="error">{{ errorMessage }}</span>
                <button type="submit">提交</button>
            </form>
        </div>
        <div>
            <p v-if="phoneRes.code === 500">
                {{phoneRes.msg}}
            </p>
            <div v-if="phoneRes.code === 0">
                <div><h3>用户岗位：{{phoneRes.data.postName}}</h3></div>
                <div><h3>用户名称：{{phoneRes.data.userName}}</h3></div>
                <div><h3>手机号码：{{phoneRes.data.phonenumber}}</h3></div>
                <div><h3>抽签顺序：{{phoneRes.data.drawLotsNum}} 号</h3></div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    name: 'HelloWorld',
    props: {
        msg: String
    },
    data() {
        return {
            qr: "",
            qrRes: [],
            phone: '',
            phoneRes: [],
        }
    },
    mounted() {
        const urlParams = new URLSearchParams(window.location.search);
        this.qr = urlParams.get('qr');
        this.fetchData()
        console.log(this.qr); // 输出: 123456
    },
    methods: {
        fetchData() {
            axios.get('/front/qr?codeId=' + this.qr)
                .then(response => {
                    this.qrRes = response.data;
                    console.log(response.data.msg);
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });
        },
        validatePhone(phone) {
            // 简单的手机号正则表达式，可以根据需要进行调整
            const phoneRegex = /^1[3-9]\d{9}$/;
            return phoneRegex.test(phone);
        },
        handleSubmit() {
            if (!this.validatePhone(this.phone)) {
                this.errorMessage = '请输入有效的手机号';
                return;
            }
            axios.get('/front/drawLots?codeId=' + this.qr + "&phonenumber=" + this.phone)
                .then(response => {
                    this.phoneRes = response.data;
                    console.log('提交成功:', response.data);
                })
                .catch(error => {
                    console.error('提交失败:', error);
                });
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #42b983;
}

/* 添加一些简单的样式 */
.phone-box {
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center;     /* 垂直居中 */
    width: 100%;
}

form {
    display: flex;
    flex-direction: column;
    width: 200px;
}

label {
    margin-bottom: 8px;
}

input {
    margin-bottom: 16px;
    padding: 8px;
    font-size: 16px;
}

button {
    padding: 8px;
    font-size: 16px;
    background-color: #42b983;
    color: white;
    border: none;
    cursor: pointer;
}

button:hover {
    background-color: #369f75;
}

.error {
    color: red;
    margin-top: -16px;
    margin-bottom: 16px;
}
</style>
